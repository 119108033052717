<template>
  <div class="table-div">
    <table border="0" cellspacing="0" cellpadding="0" class="doc-detail-table">
      <tbody>
        <tr>
          <td style="min-width: 90px;">验收单编号</td>
          <td style="min-width: 90px;">{{data.code}}</td>
          <td style="min-width: 90px;">验收时间</td>
          <td style="min-width: 90px;" class="right-td">{{data.acceptanceTime ? data.acceptanceTime.slice(0, 10) : ''}}</td>
        </tr>
        <tr>
          <td>验收内容</td>
          <td class="right-td" colspan="3">{{data.content}}</td>
        </tr>
        <tr>
          <td colspan="2" class="td-center">物品名称</td>
          <td class="td-center">数量</td>
          <td class="right-td td-center">单价</td>
        </tr>
        <template v-if="data.detailList.length > 0">
        <tr v-for="item in data.detailList" :key="item.id">
          <td colspan="2" class="td-center">{{item.purchase ? item.purchase.goodsName : ''}}</td>
          <td class="td-center">{{item.num}}</td>
          <td class="right-td td-center">{{item.price}}</td>
        </tr>
        </template>
        <template v-else>
          <tr>
            <td class="right-td td-center" colspan="4">暂无相关物品</td>
          </tr>
        </template>
        <tr>
          <td>使用科室</td>
          <td colspan="3" class="right-td td-center">{{getHtml('use')}}</td>
        </tr>
        <tr>
          <td>职能科室</td>
          <td colspan="3" class="right-td td-center">{{getHtml('purchase')}}</td>
        </tr>
        <tr>
          <td class="bottom-td">固定资产管理科室</td>
          <td colspan="3" class="right-td bottom-td td-center">{{getHtml('acceptance')}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    data: { type: Object, defualt: () => null }
  },
  methods: {
    getHtml (orgType) {
      return (this.data[orgType + 'OrgContent'] || '') + ' ' + (this.data[orgType + 'OrgUserName'] || '') + ' ' + (this.data[orgType + 'OrgTime'] ? this.data[orgType + 'OrgTime'].slice(0, 10) : '')
    }
  }
}
</script>

<style lang="less" scoped>
.table-div {
  width: 100%;
  height: calc(100% - 48px);
  overflow-y: auto;
}
.doc-detail-table {
  width: calc(100% - 20px);
  padding-left: 20px;
  td {
    font-size: 14px;
    padding: 0 8px;
    line-height: 30px;
    border-top: 1.5px solid #F4628F;
    border-left: 1.5px solid #F4628F;
  }
  .td-center {
    text-align: center;
  }
  .right-td {
    border-right: 1.5px solid #F4628F;
  }
  .bottom-td {
    border-bottom: 1.5px solid #F4628F;
  }
}
</style>