<template>
  <div>
    <div class="content" :style="{'max-width': maxWidth + 'px'}" @mouseover="open = true" @mouseout="open = false">{{content}}</div>
    <fm-poper :value="open" position="right-end">
      <div class="long-content">
        {{content}}
      </div>
    </fm-poper>
  </div>
</template>

<script>
export default {
  data () {
    return {
      open: false
    }
  },
  methods: {
    copen () {
      this.open = !this.open
    }
  },
  props: {
    content: {
      type: String
    },
    maxWidth: {
      type: Number, default: 270
    }
  }
}
</script>

<style lang="less" scoped>
.long-content {
  max-width: 300px;
  border-radius: 10px;
  border: 1px solid #ccc;
  background: #fff;
  padding: 10px 15px;
}
.content {
  overflow: hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
}
</style>